<template>
  <!-- begin:: Footer -->
  <div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
    <div
      class="d-flex align-items-center justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="nav nav-dark">
        <a href="https://www.bipart.org" target="_blank" class="nav-link pr-3 pl-0">
          {{$t("MAINMENU.ABOUT")}}
        </a>
        <router-link to="/privacy"  class="nav-link px-3">
          {{$t("MAINMENU.PRIVACY")}}
        </router-link>
        <router-link to="/terms"  class="nav-link pl-3 pr-0">
          {{$t("MAINMENU.TERMS")}}
        </router-link>
        <router-link to="/cookie"  class="nav-link pl-3 pr-0">
          {{$t("MAINMENU.COOKIE")}}
        </router-link>
         <router-link
          href="https://form.agid.gov.it/view/c6204fc8-159b-40f0-bdf0-14c628b13a4b/"
          target="_blank"
          class="nav-link pl-3 pr-0"
          v-if="domain.includes('partecipo-romagnafaentina.it')"
        >
          {{$t("MAINMENU.ACCESSIBILITY")}}
        </router-link>

      </div>

      <div class="text-dark">
        <span class="text-muted font-weight-bold mr-2">
          2021 &nbsp;&copy;&nbsp; BiPart impresa sociale srl
        </span>

      </div>
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  data() {
    return {
      domain : window.location.host
    }
  },
  computed: {
    ...mapGetters([
        "layoutConfig",
      "getOrganization"
    ]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    }
  }
};
</script>
