var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav mt-0 overflow-hidden"},[_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MAINMENU.WORKSPACE")))]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]),(this.currentUser.authorities && !this.currentUser.authorities.includes('SCOUT'))?_c('router-link',{attrs:{"to":"/personal-space"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item mt-4",class:[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"btn btn-icon w-auto d-flex justify-content-start btn-lg px-2",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"symbol symbol-circle",style:(_vm.$imageOptions.styleSettings({width: 50, height: 50, userImage: (_vm.currentUser.avatar ? _vm.currentUser.avatar : _vm.$imageOptions.defaultAvatarUrl)}))}),_c('span',{staticClass:"menu-text ml-4"},[_vm._v(" "+_vm._s(_vm.$t("MAINMENU.PS")))])])])]}}],null,false,3314711868)}):_vm._e(),_c('router-link',{attrs:{"to":{name: 'processList'}},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item mt-4",class:[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"btn btn-icon w-auto d-flex justify-content-start btn-lg px-2",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"symbol symbol-circle",style:(_vm.$imageOptions.styleSettings({width: 50, height: 50, userImage: ((_vm.getOrganization.data && _vm.getOrganization.data.logo4Uri) ? _vm.getOrganization.data.logo4Uri : '/assets/media/bipart/bipart-logo-minimal.png')}))}),_c('span',{staticClass:"menu-text ml-4"},[_vm._v(_vm._s(_vm.$t("MAINMENU.DS")))])])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }