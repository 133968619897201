<template>
  <!--begin::Menu Nav-->
  <ul class="menu-nav mt-0 overflow-hidden">
    <li class="menu-section">
      <h4 class="menu-text">{{$t("MAINMENU.WORKSPACE")}}</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <router-link
        to="/personal-space"
        v-slot="{ href, navigate, isActive, isExactActive }"
        v-if="this.currentUser.authorities && !this.currentUser.authorities.includes('SCOUT')"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item mt-4"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
        <a :href="href" @click="navigate"  class="btn btn-icon w-auto d-flex justify-content-start btn-lg px-2">
<!--          <span class="symbol symbol-circle" style="width: 50px; height: 50px">-->
<!--              <img :src="currentUser.avatar" style="width: 100%">-->
<!--          </span> -->
          <span class="symbol symbol-circle" :style="$imageOptions.styleSettings({width: 50, height: 50, userImage: (currentUser.avatar ? currentUser.avatar : $imageOptions.defaultAvatarUrl)})">
          </span>
          <span class="menu-text ml-4"> {{$t("MAINMENU.PS")}}</span>
        </a>
      </li>
    </router-link>
    <router-link
        :to="{name: 'processList'}"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item mt-4"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
        <a :href="href" @click="navigate"  class="btn btn-icon w-auto d-flex justify-content-start btn-lg px-2">
          <span class="symbol symbol-circle" :style="$imageOptions.styleSettings({width: 50, height: 50, userImage: ((getOrganization.data && getOrganization.data.logo4Uri) ? getOrganization.data.logo4Uri : '/assets/media/bipart/bipart-logo-minimal.png')})">
<!--            <span class="symbol-label">-->
<!--              <img -->
<!--               alt="Logo"-->
<!--              :src="(getOrganization.data && getOrganization.data.logo4Uri) ? getOrganization.data.logo4Uri : '/assets/media/bipart/bipart-logo-minimal.png'"-->
<!--              class="w-100 h-100 align-self-center circle p-0 m-0"-->
<!--              >-->
<!--            </span>-->
          </span>
          <span class="menu-text ml-4">{{$t("MAINMENU.DS")}}</span>
        </a>
      </li>
    </router-link>
    <!-- <router-link
        to="/community-space"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item mt-4"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
        <a :href="href" @click="navigate" class="btn btn-icon w-auto d-flex justify-content-start btn-lg px-2">
          <span class="symbol symbol-circle">
            <span class="symbol-label font-size-h2 font-weight-bold">CS</span>
          </span>
          <span class="menu-text ml-4">Community Space</span>
        </a>
      </li>
    </router-link> -->
  </ul>

</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses", "getOrganization", "currentUser"])
  }

};
</script>
