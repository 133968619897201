<template>
  <div class="d-flex flex-column flex-root">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      <KTAside v-if="asideEnabled"></KTAside>
      <!-- end:: Aside Left -->

      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->
        <KTHeader></KTHeader>
        <!-- end:: Header -->

        <!--Email Pending-->
        <div v-if="is_email_pending" class="text-white bg-danger p-3 text-center">{{ $t('LAYOUT.CONFIRMEMAIL1') }}, <a href="#" @click.prevent="resendEmail" class="text-white font-weight-bolder text-underline">{{ $t('LAYOUT.CONFIRMEMAIL2') }}</a> {{ $t('LAYOUT.CONFIRMEMAIL3') }}</div>

        <!--Mobile Pending-->
        <div v-if="is_mobile_pending" class="text-white bg-danger p-3 text-center">{{ $t('LAYOUT.CONFIRMMOBILE1') }}, <a href="#" @click.prevent="resendOptMobile" class="text-white font-weight-bolder text-underline">{{ $t('LAYOUT.CONFIRMMOBILE2') }}</a> {{ $t('LAYOUT.CONFIRMMOBILE3') }}</div>

        <!-- begin:: Content -->
        <div
            id="kt_content"
            class="d-flex flex-column flex-column-fluid"
        >
          <!-- begin:: Content Head -->

          <!-- begin:: Content Head -->
          <KTSubheader
              v-if="subheaderDisplay && breadcrumbs.length"
              v-bind:breadcrumbs="breadcrumbs"
              v-bind:title="pageTitle"
          />
          <div v-else class="subheader py-2 py-lg-4 m-0 subheader-solid bg-secondary d-none d-sm-block" id="kt_subheader">
            <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
              <div class="d-flex align-items-center flex-wrap mr-2">
                <h5 class="text-white font-weight-bold mt-2 mb-2 mr-5"></h5>
              </div>
              <div class="d-flex align-items-center">
                <!-- <a href="layout/bipart/register.html" class="btn btn-outline-white text-white font-weight-bolder btn-sm disabled" >Registrati</a>
                <a href="layout/bipart/login.html" class="btn btn-warning font-weight-bolder btn-sm ml-2 disabled">Accedi</a> -->
              </div>
            </div>
          </div>
          <!-- end:: Content Head -->
          <!-- <transition name="fade-in-up"> -->
          <router-view />
          <!-- </transition> -->

        </div>
        <KTFooter></KTFooter>
      </div>
    </div>
    <KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar>
    <KTScrollTop></KTScrollTop>

    <!-- <Cookie></Cookie> -->

    <vue-cookie-accept-decline
        :ref="'myPanel1'"
        :elementId="'myPanel1'"
        :debug="false"
        :position="'bottom-right'"
        :transitionName="'slideFromBottom'"
        @status="cookieStatus"
        @clickedAccept="cookieClickedAccept"
        @clickedDecline="cookieClickedDecline">
      <div slot="message" class="lead">
        This website uses cookies.<br>
        See our <router-link to="/privacy" target="_blank">Privacy Policy</router-link>
        and <a href="https://www.cookiesandyou.com/" target="_blank">Cookie Policy</a> to learn more.
      </div>
    </vue-cookie-accept-decline>

    <div  id="customCss"></div>

    <adv-mobile-verify
      ref="mobileVerifyLayout"   
      :see-back="false"   
    ></adv-mobile-verify>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeader from "@/view/layout/header/Header.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";
//import Cookie from "@/view/layout/footer/Cookie.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTSubheader from "@/view/layout/subheader/Subheader.vue";
import KTStickyToolbar from "@/view/layout/extras/StickyToolbar.vue";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/view/content/Loader.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import i18n from "@/core/plugins/vue-i18n";
import i18nService from "@/core/services/i18n.service";
import AdvMobileVerify from "@/view/pages/auth/partials/advAuthMobileVerify";
import {RESEND_OTP_CODE} from "@/core/services/store/auth.module";

export default {
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    KTSubheader,
    KTStickyToolbar,
    KTScrollTop,
    Loader,
    //Cookie
    AdvMobileVerify,
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    // check if current user is authenticated
    // console.log('layout mounted');
    // if (!this.isAuthenticated) {
    // console.log(this.getOrganization.data.publiclyVisible);
    if (this.getOrganization.data) {
      if (!this.getOrganization.data.publiclyVisible && !this.isAuthenticated) {
        this.$router.push({name: "login", query: {confirmedEmail: 'true'}});
      }
    } else {
      setTimeout( () => {
        if(this.getOrganization.data) {
          if (!this.getOrganization.data.publiclyVisible && !this.isAuthenticated) {
            this.$router.push({name: "login", query: {confirmedEmail: 'true'}});
          }
        }
      }, 50);

    }

    // Simulate the delay page loading
    // Remove page loader after some time
    setTimeout(() => {
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 1000);

    setTimeout(() => {
      let customCss = document.getElementById("customCss");
      if(this.getOrganization.data) {
        customCss.innerHTML = "<style>.bg-primary, .label-primary { background-color: " + this.getOrganization.data.primaryColor  + "!important; } .bg-warning { background-color: " + this.getOrganization.data.secondaryColor  + "!important;} .btn-primary { background-color: " + this.getOrganization.data.primaryColor  + "!important; border: 0; color: #fff!important; } .btn-primary:hover { background-color: " + this.getOrganization.data.secondaryColor  + "!important; border: 0; color: #fff!important; }  .text-primary { color: " + this.getOrganization.data.secondaryColor  + "!important;} .svg-icon-primary { color: " + this.getOrganization.data.secondaryColor  + "!important;} .bg-secondary { background-color: " + this.getOrganization.data.secondaryColor  + "!important; color: #fff !important;} .menu-text { font-weight: 600;} .nav-link.active{ color: " + this.getOrganization.data.primaryColor  + "!important;} .border-bottom-primary { border-bottom-color: " + this.getOrganization.data.primaryColor  + "!important;} .btn-default { background-color: #efefef!important;} .rectangle{background-color: " + this.getOrganization.data.primaryColor  + "!important;} .card-border{border-color: " + this.getOrganization.data.secondaryColor  + "!important;} .btn.btn-outline-primary{ border-color: " + this.getOrganization.data.primaryColor  + "!important; color: " + this.getOrganization.data.primaryColor  + "!important;} .btn.btn-outline-primary:hover:not(.btn-text), .btn.btn-outline-primary:focus:not(.btn-text), .btn.btn-outline-primary.focus:not(.btn-text){ border-color: " + this.getOrganization.data.secondaryColor  + "!important; color: " + this.getOrganization.data.secondaryColor  + "!important;background-color: transparent !important; } .link{color: " + this.getOrganization.data.secondaryColor  + "!important; }</style>";
        i18n.locale = i18nService.getActiveLanguage();
      }
    }, 100);

  },
  watch: {
    getOrganization: {
      handler() {
        this.setCss();
      },
      immediate: true
    }
  },
  methods: {
    setCss() {
      if(this.getOrganization.data != null) {
        let customCss = document.getElementById("customCss");
        if (customCss != null) {
          customCss.innerHTML = "<style>.bg-primary, .label-primary { background-color: " + this.getOrganization.data.primaryColor + "!important; } .bg-warning { background-color: " + this.getOrganization.data.secondaryColor + "!important;} .btn-primary { background-color: " + this.getOrganization.data.primaryColor + "!important; border: 0; color: #fff!important; } .btn-primary:hover { background-color: " + this.getOrganization.data.secondaryColor + "!important; border: 0; color: #fff!important; }  .text-primary { color: " + this.getOrganization.data.secondaryColor + "!important;} .svg-icon-primary { color: " + this.getOrganization.data.secondaryColor + "!important;} .bg-secondary { background-color: " + this.getOrganization.data.secondaryColor + "!important; color: #fff !important;} .menu-text { font-weight: 600;} .nav-link.active{ color: " + this.getOrganization.data.primaryColor + "!important;} .border-bottom-primary { border-bottom-color: " + this.getOrganization.data.primaryColor + "!important;} .btn-default { background-color: #efefef!important;} .rectangle{background-color: " + this.getOrganization.data.primaryColor + "!important;} .card-border{border-color: " + this.getOrganization.data.secondaryColor + "!important;} .btn.btn-outline-primary{ border-color: " + this.getOrganization.data.primaryColor + "!important; color: " + this.getOrganization.data.primaryColor + "!important;} .btn.btn-outline-primary:hover:not(.btn-text), .btn.btn-outline-primary:focus:not(.btn-text), .btn.btn-outline-primary.focus:not(.btn-text){ border-color: " + this.getOrganization.data.secondaryColor + "!important; color: " + this.getOrganization.data.secondaryColor + "!important;background-color: transparent !important; } .link{color: " + this.getOrganization.data.secondaryColor + "!important; }</style>";
        }
        i18n.locale = i18nService.getActiveLanguage();
      }
    },
    cookieStatus (status) {
      this.status = status
    },
    cookieClickedAccept () {
      this.status = 'accept'
    },
    cookieClickedDecline () {
      this.status = 'decline'
    },
    cookieRemovedCookie () {
      this.status = null
      this.$refs.myPanel1.init()
    },

    removeCookie () {
      this.$refs.myPanel1.removeCookie()
    },

    resendEmail (){
      // Reinvio di mail per richiesta conferma mail
      new Promise((resolve, reject) => {
        ApiService.put(this.$apiResource + "/users/" + this.currentUser.id + "/resend-confirmation-email-for-registration", {})
            .then((res) => {
              if(res.status == 200) {
                Swal.fire( {
                  title: "",
                  html: this.$t('ALERT.ALERTEMAILRESENDCONFIRM'),
                  icon: ""
                });
              } else {
                Swal.fire('Warning', this.$t('ALERT.ALERTERROR'), 'info')
              }
              resolve(1);
            })
            .catch((err) => {
              let code = err.response.data.message.split('|')[0].split(':')[1];
              let textMEssage = this.$t('MESSAGEAUTH.0');

              if(code.toString()) {
                textMEssage = this.$t('MESSAGEAUTH.' + code.toString());
              }

              Swal.fire({
                title: this.$t('REGISTER.ATTENZIONE'),
                text: textMEssage,
                icon: "error",
                confirmButtonClass: "btn btn-secondary"
              });

              reject(0);
            });
      });
    },

    resendOptMobile () {      
      this.$store.dispatch(RESEND_OTP_CODE, this.currentUser.id);
      this.$refs["mobileVerifyLayout"].$refs["modalSms"].show();
    },

  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
      "getOrganization",
      "currentUser"
    ]),

    is_email_pending() {      
      return  (this.currentUser.selectedOrganizationPublic && this.currentUser.emailRegStatus == 'PENDING')
    },

    is_mobile_pending() {         
      return  (this.currentUser.selectedOrganizationPublic && this.currentUser.mobileRegStatus == 'PENDING')
    },

    statusText () {
      return this.status || 'No cookie set'
    },

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      // return true;
      return false;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    }
  }
};
</script>
