<template>

  <div
    class="subheader py-2 py-lg-4 bg-warning text-white"
    v-bind:class="subheaderClasses"
    id="kt_subheader"
  >
    <div
      class="d-flex align-items-end justify-content-start flex-wrap flex-sm-nowrap"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="d-flex align-items-center flex-wrap mr-2">
        <ul
          class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2"
        >
          <template v-for="(breadcrumb, i) in breadcrumbs">
            <li :key="`${i}-${breadcrumb.id}`" class="breadcrumb-item">
              <router-link
                v-if="breadcrumb.route && breadcrumb.route != '' && breadcrumb.route != 'disabled'"
                :key="i"
                :to="breadcrumb.route"
                class="text-muted"
                :disabled="false"
              >
                <span class="breadcrumb-link">{{ breadcrumb.title }}</span>

              </router-link>
              <router-link
                v-else-if="breadcrumb.route && breadcrumb.route == 'disabled'"
                :key="i"
                to=""
                class="text-muted"
                :disabled="true"
              >
                <span class="breadcrumb-link">{{ breadcrumb.title }}</span>

              </router-link>

              <span class="font-weight-bolder" :key="i" v-if="!breadcrumb.route">
                {{ breadcrumb.title }}
<!--                {{ getCurrentProcess.title }} {{ getCurrentProcess.processPhases.filter(phase => phase.id == 162)[0].title }} {{  }}-->
              </span>
            </li>
            
          </template>
        </ul>
      </div>
      
      
    </div>
  </div>
</template>

<style lang="scss">

.breadcrumb-link {
  width: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.breadcrumb-link:hover {
  width: auto;
}

.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTSubheader",
  props: {
    breadcrumbs: Array,
    title: String
  },

  computed: {
    ...mapGetters(["layoutConfig", "getCurrentProcess", "getCurrentPhase"]),

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },

    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig("subheader.style");
      if (style) {
        classes.push(style);

        if (style === "solid") {
          classes.push("bg-white");
        }

        if (this.layoutConfig("subheader.fixed")) {
          classes.push("border-top");
        }
      }
      return classes.join(" ");
    }
  }
};
</script>


